<template>
  <div class="rule-page">
    <div class="ruleIcon">
			<img src="./img/ruleIcon.png" />
		</div>
    <div class="ruleContent">
			<ul>
				<li>1、花费相应的金豆可以兑换对应定制产品，每位用户，每月仅可兑换一次；</li>
				<li>2、金豆一经使用，不可退还，请确认清楚是否兑换。已兑换产品，不可更换，不可转让或者兑现；</li>
				<li>3、港、澳、台及海外地区暂不支持派送，请正确填写收货信息，若由于您自身原因导致的收货失败，有鱼记账不承担任何责任。</li>
				<li>4、已兑换的商品在下单后，会在规定时间内安排发货。若由于政策，自然灾害等不可抗力因素导致的发货延迟，有鱼不承担任何责任；</li>
				<li>5、由于兑换产品为定制类产品，非质量原因外，不支持任何无理由退换。</li>
				<li>6、定制商品时上传图片内容为用户自发行为，请确保上传内容合法合规，若产生任何纠纷，则由上传者自身承担。</li>
				<li>7、商品每月数量有限，兑完为止；</li>
				<li>8、通过非法途径获得金豆后进行的兑换，或不按本站使用规则进行兑换，本站有权不提供服务并收回金豆;</li>
        <li>9、凡以不正当手段〈包括但不限于作弊、扰乱系统、实施网络攻击等)进行兑换,平台有权终止您的兑换权利。</li>
        <li>10、最终解析权归有鱼所有，若有任何疑问请随时联系客服进行咨询处理。</li>
			</ul>
      <p class="weixin">微信号：youyujz002（工作日：9：00-18：00)</p>
		</div>
  </div>
</template>
<script>
export default {
  name: 'GoldRule',
  mounted() {
    document.title = '活动规则';
  },
};
</script>
<style scoped lang="scss">
  .rule-page {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 0.1px;
    overflow-y: auto;
    .ruleIcon {
      width: 18.9%;
      margin: 0.253333rem 0 0.266666rem 0;
      img {
        width: 100%;
      }
    }
    .ruleContent {
      width: 91.9%;
      margin: 0 auto;
      .weixin {
        font-size: .37rem;
        margin-top: .5rem;
      }
    }
    .ruleContent > ul > li {
      font-size: 0.373333rem;
      line-height: 1.86;
      letter-spacing: -0.2px;
      color: #333333;
    }
  }
</style>
